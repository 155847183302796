
.ListItems {
  margin: 20px 0;
}

.ListItems .ListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 42px;
  padding: 10px 4px;
  border-bottom: 1px solid #D6D6D6;
  cursor: pointer;
}

.ListItems .ListItem .ListCol1 {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  width: 40%;
}
.ListItems .ListItem .ListCol2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 10%;
}
.ListItems .ListItem .ListCol3 {
  flex-grow: 1;
  width: 10%;
}
.ListItems .ListItem .ListCol4 {
  flex-grow: 1;
  width: 10%;
}
.ListItems .ListItem .ListCol5 {
  flex-grow: 1;
  width: 10%;
}

.ListItemProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ListItemSubtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #565656;
  padding-left: 41px;
}

.ListItemSubtitlePersona {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #565656;
}

.ListItemProfilePhoto {
  padding: 0 8px;
}

.ListItemProfilePhoto img {
  width: 24px;
  height: 24px;
}

.ListItems .ListItem .ListCategoryTitle {
  flex-grow: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;  
}

.ListItems .ListItem .ListCol1 .ListTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.ListItems .ListItem .ListCol1 .ListDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
}

.categoryButton {
  font-weight: 600;
  background: #E8E8E8;
  border-radius: 6px;
  border: 0;
  outline: none;
	cursor: pointer;
  width: 36px;
  height: 36px;
}

