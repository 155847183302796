
.AdminAddPad-form {
  display: flex;
  flex-direction: column;
}

.AdminAddPad-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.AdminAddPad-item label {
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  max-width: 10%;
}

.AdminAddPad-item input, .AdminAddPad-item textarea, .AdminAddPad-item select {
  font-size: 16px;
  padding: 5px;
  width: 400px;
}

.AdminAddPad-item .AdminAddPad-item-info {
  margin-left: 10px;
}