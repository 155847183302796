.App {
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: stretch;
  font-size: 22px;
  color: #1E1E1E;
  padding: 10px;
  box-shadow: 0px 2px 6px rgba(15, 15, 15, 0.08);
}
.App-logo {
  border-right: 1px solid #D6D6D6;
  padding: 2px 16px;
}
.App-title {
  padding: 2px 16px;
  flex-grow: 1;
}
.App-header .App-welcome {
  margin: 0 12px;
}
.App-header .App-button {
  
}

.App-body {
  margin: 12px;
  display: flex;
  flex-direction: column;
}

.App-login-container {
  display: flex;
  flex-direction: column;
  margin: 20px 60px;
  align-items: center;
}

.App-login-body {
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  /* background-color: #F2F2F2; */
  max-width: 800px;
}

.App-login-body h1 {
  font-size: 22px;

}
.App-login-body h2 {
  font-size: 18px;
}
.App-login-body p.App-subheader {
  font-size: 16px;
  font-weight: bold;
}
.App-login-body p {
  font-size: 16px;
  margin: 0.6em 0;
}

.App-signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-top: 1em;
}

.App-signin button {
  background-image: url(./btn_google_signin_dark_normal_web.png);
  background-size: cover;
  background-color: transparent;
  width: 191px;
  height: 46px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  cursor: pointer;
}

.App-signout, .App-add-pad {
  padding: 2px 16px;
}

.App-signout button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  color: #1842EF;
}

.App-add-pad button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  color: #ef1818;
}

.Pad {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.Pad-iframe {
  display: flex;
  height: calc(100vh - 90px);
}
.embed-iframe {
  flex-grow: 1;
  border: none; 
  margin: 0; 
  padding: 0;
}

button.navButton {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #1842EF;
}